import _ from "lodash"
import { LRUCache } from "shared-libs/models/cache"

const TRANSLATION_CACHE_SIZE = 100

const translateEndpoint = 'https://translation.googleapis.com/language/translate/v2?key=AIzaSyA7qX8vBcsAFCtGlX-X_-TyKdWtP1y0qPU&format=text'

class TranslationManagerImpl {
    // key is source locale code ":" source string, and value is translated string
    private cache = new LRUCache<string, string>(TRANSLATION_CACHE_SIZE)

    public translateString(text: string, sourceLocale: string, targetLocale: string): Promise<string> {
        // 'en' should match 'en-US', though 'en-GB' should not match 'en-US'
        if (_.isEmpty(text) || _.isEmpty(sourceLocale) || _.isEmpty(targetLocale) 
            || sourceLocale.startsWith(targetLocale) || targetLocale.startsWith(sourceLocale)) {
            return Promise.resolve(text)
        }
        const key = sourceLocale + ':' + text
        if (this.cache.has(key)) {
            return Promise.resolve(this.cache.get(key))
        }
        const sourceSpec = _.isEmpty(sourceLocale) ? '' : `&source=${sourceLocale}`
        const request = translateEndpoint + sourceSpec + `&target=${targetLocale}&q=${text}`
        const headers = new Headers()
        return fetch(request, {
            headers
        })
            .then((response) => response.json())
            .then((translated) => {
                const translation = _.get(translated, 'data.translations.0.translatedText')
                this.cache.set(key, translation)
                return translation
            })
            .catch((e) => console.warn(e.message))
    }
}

export const TranslationManager = new TranslationManagerImpl()